import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FeatherIcon, LogoIcon } from '../../assets/icons/icons';
import './Footer.css';
import {
  CREATORS_LINK,
  FOOTER_NAV_LIST,
  HOME_LINK,
  SOCIAL_LINKS,
  TAP_BTN_SCALE,
  THEME_DARK_TYPE,
} from '../../assets/utils/constants';
import { ThemeContext } from '../../assets/contexts/themeContext';

const btnHoverAnimation = {
  opacity: 0.8,
};

const btnTapAnimation = {
  scale: TAP_BTN_SCALE,
};

export default function Footer({ isInverted = false }) {
  const { theme } = useContext(ThemeContext);

  return (
    <footer
      className="footer"
      style={{
        '--btn-bg-color': isInverted
          ? 'var(--neutral-active)'
          : 'var(--footer-neutral-primary)',
        '--btn-border-color':
          theme === THEME_DARK_TYPE
            ? 'var(--neutral-active)'
            : 'var(--btn-bg-color)',
        '--btn-text-color': isInverted
          ? 'var(--neutral-contrast-secondary)'
          : 'var(--neutral-active)',
        '--logo-bg-color':
          theme === THEME_DARK_TYPE
            ? 'var(--main-primary-active)'
            : 'var(--neutral-active)',
        '--logo-border-color':
          theme === THEME_DARK_TYPE
            ? 'var(--main-primary-active)'
            : 'var(--neutral-active)',
        '--logo-fill-color':
          theme === THEME_DARK_TYPE
            ? 'var(--neutral-active)'
            : 'var(--neutral-contrast-secondary)',
      }}
    >
      <div className="footer__container">
        <ul className="footer__links-list footer__links-list_type_wrap">
          <li className="footer__link-item">
            <Link className="footer__icon-link" to={HOME_LINK.path}>
              <motion.div
                className="footer__icon-box"
                whileHover={btnHoverAnimation}
                whileTap={btnTapAnimation}
              >
                <LogoIcon
                  mainClassName="footer__logo-icon"
                  backgroundClassName="footer__logo-icon-background"
                  borderClassName="footer__logo-icon-border"
                  fillClassName="footer__logo-icon-fill"
                />
              </motion.div>
            </Link>
          </li>

          {FOOTER_NAV_LIST.map((item) => (
            <li className="footer__link-item" key={item.label}>
              <Link className="footer__link" to={item.path}>
                <motion.div
                  className="footer__btn"
                  whileHover={btnHoverAnimation}
                  whileTap={btnTapAnimation}
                >
                  {item.label}
                </motion.div>
              </Link>
            </li>
          ))}
        </ul>

        <motion.a
          className="footer__btn footer__btn_inverted"
          href={CREATORS_LINK}
          rel='noreferrer'
          target='_blank'
          whileHover={btnHoverAnimation}
          whileTap={btnTapAnimation}
        >
          <FeatherIcon
            mainClassName="footer__btn-icon"
            fillClassName="footer__btn-icon-fill"
          />
          <span className="footer__btn-text">Become a Creator</span>
        </motion.a>

        {SOCIAL_LINKS.some((item) => item.link) && (
          <ul className="footer__links-list">
            {SOCIAL_LINKS.map(
              (item) =>
                item.link && (
                  <li className="footer__link-item" key={item.label}>
                    <motion.a
                      className="footer__social-link"
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      whileHover={btnHoverAnimation}
                      whileTap={btnTapAnimation}
                    >
                      {item.icon({
                        mainClassName: 'footer__link-icon',
                        backgroundClassName: 'footer__link-icon-background',
                        fillClassName: 'footer__link-icon-fill',
                      })}
                    </motion.a>
                  </li>
                )
            )}
          </ul>
        )}
      </div>
    </footer>
  );
}
