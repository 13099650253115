import { useEffect, useRef, useState } from "react";
import validator from "validator";
import Input from "../Input/Input";
import Popup from "../Popup/Popup";
import { WAITLIST_POPUP_NAME } from "../../assets/utils/constants";
import tgFormApi from "../../assets/api/TgFormApi";
import "./WaitlistPopup.css";

function WaitlistPopup({ setOpen, isOpen }) {
    const inputRef = useRef();

    useEffect(() => {
        if (isOpen) {
            inputRef.current?.focus(); // Ensure the ref is valid before calling focus
        }
    }, [isOpen]);

    const [validity, setValidity] = useState({
        email: {},
    });
    const [value, setValue] = useState({
        email: "",
    });

    function onClosePopup() {
        setTimeout(() => {
            setValue({
                email: "",
            });
            setValidity({
                email: {},
            });
        }, 300);
    }

    function handleChange(e) {
        const input = e.target;
        const name = input.name;
        let value = input.value;

        switch (name) {
            case "email":
                if (!value) {
                    setValidity((prevValue) => ({
                        ...prevValue,
                        [name]: {
                            errorMessage: "",
                            validState: false,
                        },
                    }));
                }
                if (value.length >= 2) {
                    if (validator.isEmail(value)) {
                        setValidity((prevValue) => ({
                            ...prevValue,
                            [name]: {
                                errorMessage: "",
                                validState: true,
                            },
                        }));
                    } else {
                        setValidity((prevValue) => ({
                            ...prevValue,
                            [name]: {
                                errorMessage: !e.target.validity.valid
                                    ? e.target.validationMessage
                                    : "Invalid email",
                                validState: false,
                            },
                        }));
                    }
                }
                setValue((prevValue) => ({
                    ...prevValue,
                    [name]: value,
                }));
                break;

            default:
                setValue((prevValue) => ({
                    ...prevValue,
                    [name]: value,
                }));
                break;
        }
    }

    function handleSubmit() {
        tgFormApi
            .sendWaitlist({ email: value.email })
            .then((res) => {
                // Handle success
            })
            .catch(() => {
                // Handle error
            })
        setOpen((prevValue) => ({
            ...prevValue,
            [WAITLIST_POPUP_NAME]: false,
        }));
        onClosePopup();
    }

    return (
        <Popup
            className="waitlist-popup"
            popupName={WAITLIST_POPUP_NAME}
            onClose={onClosePopup}
            setOpen={setOpen}
            isOpen={isOpen}
        >
            <div className="waitlist-popup__heading">
                <p className="waitlist-popup__title">Be the First</p>
                <p className="waitlist-popup__subtitle">
                    Enter your email to&nbsp;join the waitlist and get exclusive updates
                </p>
            </div>

            <div className="waitlist-popup__input">
                <Input
                    ref={inputRef}
                    label={"E-mail"}
                    value={value.email}
                    handleChange={handleChange}
                    name={"email"}
                    type="email"
                />
            </div>

            <button
                className={`waitlist-popup__submit ${!(value.email && validity.email.validState)
                    ? "waitlist-popup__submit_disabled"
                    : ""
                    }`}
                type="button"
                onClick={handleSubmit}
            >
                Submit
            </button>
        </Popup>
    );
}

export default WaitlistPopup;
