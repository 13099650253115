import { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import './GeneralInfo.css';
import {
  BASE_TIMING_FUNCTION,
  BASE_TRANSITION_DURATION,
} from '../../assets/utils/constants';

const imgBoxVariants = {
  hidden: { x: '-100%' },
  visible: {
    x: 0,
    transition: {
      duration: BASE_TRANSITION_DURATION,
      ease: BASE_TIMING_FUNCTION,
    },
  },
};

export default function GeneralInfo({ children, title, text }) {
  const imgBoxRef = useRef(null);
  const imgBoxControls = useAnimation();
  const imgBoxInView = useInView(imgBoxRef, { amount: 0.4 });

  useEffect(() => {
    imgBoxInView
      ? imgBoxControls.start('visible')
      : imgBoxControls.start('hidden');
  }, [imgBoxInView, imgBoxControls]);

  return (
    <section className="general-info">
      <div className="general-info__container" ref={imgBoxRef}>
        <h2
          className={`general-info__title general-info__title_type_mobile ${
            !text ? 'general-info__title_size_big' : ''
          }`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className="general-info__content">
          <div className="general-info__img-box">{children}</div>
          <h2
            className={`general-info__title ${
              !text ? 'general-info__title_size_big' : ''
            }`}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {text && (
            <p
              className="general-info__text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </div>
      </div>
    </section>
  );
}
