import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Main from '../Main/Main';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import {
  FOOTER_CONTACT_LINK,
  FOOTER_HELP_LINK,
  FOOTER_PRIVACY_LINK,
  FOOTER_SERVICE_LINK,
  HOME_LINK,
  WAITLIST_POPUP_NAME,
} from '../../assets/utils/constants';
import StaticDocs from '../StaticDocs/StaticDocs';
import {
  PRIVACY_POLICY_TEXT,
  PRIVACY_POLICY_TITLE,
} from '../../assets/utils/legal_docs/privacy_policy';
import {
  TERMS_OF_SERVICE_TEXT,
  TERMS_OF_SERVICE_TITLE,
} from '../../assets/utils/legal_docs/user_agreement';
import {
  HELP_CENTER_TEXT,
  HELP_CENTER_TITLE,
} from '../../assets/utils/legal_docs/help-center';
import {
  CONTACT_TEXT,
  CONTACT_TITLE,
} from '../../assets/utils/legal_docs/contact';
import { useTheme } from '../../assets/hooks/useTheme';
import { ThemeContext } from '../../assets/contexts/themeContext';
import { useState } from 'react';
import { PopupContext } from '../../assets/contexts/popupContext';
import WaitlistPopup from '../WaitlistPopup/WaitlistPopup';

function App() {
  const [popups, setPopups] = useState({
    [WAITLIST_POPUP_NAME]: false,
  })

  function openPopup(popupName) {
    setPopups(prevState => ({
      ...prevState,
      [popupName]: true,
    }))
  }

  const { theme, setTheme } = useTheme();
  const { pathname } = useLocation();
  const isStaticDocs =
    pathname.includes(FOOTER_PRIVACY_LINK.path) ||
    pathname.includes(FOOTER_SERVICE_LINK.path) ||
    pathname.includes(FOOTER_HELP_LINK.path) ||
    pathname.includes(FOOTER_CONTACT_LINK.path);

  return (
    <div className="app">
      <PopupContext.Provider value={{ popups, openPopup }}>
        <ThemeContext.Provider value={{ setTheme, theme }}>
          <WaitlistPopup isOpen={popups[WAITLIST_POPUP_NAME]} setOpen={setPopups} />
          {!isStaticDocs && <Header />}
          <div className="app__content">
            <Routes>
              <Route path={HOME_LINK.path} element={<Main />} />
              <Route
                path={FOOTER_PRIVACY_LINK.path}
                element={
                  <StaticDocs
                    title={PRIVACY_POLICY_TITLE}
                    text={PRIVACY_POLICY_TEXT}
                  />
                }
              />
              <Route
                path={FOOTER_SERVICE_LINK.path}
                element={
                  <StaticDocs
                    title={TERMS_OF_SERVICE_TITLE}
                    text={TERMS_OF_SERVICE_TEXT}
                  />
                }
              />
              <Route
                path={FOOTER_HELP_LINK.path}
                element={
                  <StaticDocs title={HELP_CENTER_TITLE} text={HELP_CENTER_TEXT} />
                }
              />
              <Route
                path={FOOTER_CONTACT_LINK.path}
                element={<StaticDocs title={CONTACT_TITLE} text={CONTACT_TEXT} />}
              />
            </Routes>
          </div>
          {!isStaticDocs && <Footer />}
        </ThemeContext.Provider>
      </PopupContext.Provider>
    </div>
  );
}

export default App;
