import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './StaticDocs.css';
import { TAP_BTN_SCALE } from '../../assets/utils/constants';
import { CloseIcon } from '../../assets/icons/icons';

export default function StaticDocs({ title, text }) {
  const navigate = useNavigate();

  return (
    <div className="static-docs">
      <motion.button
        className="static-docs__close-btn"
        type="button"
        whileHover={{ opacity: 0.8 }}
        whileTap={{ scale: TAP_BTN_SCALE }}
        onClick={() => navigate(-1)}
      >
        <CloseIcon
          mainClassName="static-docs__close-icon"
          backgroundClassName="static-docs__close-icon-background"
          fillClassName="static-docs__close-icon-fill"
        />
      </motion.button>
      <div className="static-docs__content">
        <h1 className="static-docs__title">{title}</h1>
        <div
          className="static-docs__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
}
