import LineDivider from '../../LineDivider/LineDivider';
import BenefitsList from '../../BenefitsList/BenefitsList';
import Depths from '../Depths/Depths';
import StoryFormats from '../StoryFormats/StoryFormats';
import './MainAbout.css';
import card1 from '../../../assets/images/main/muchi-benefits/card1.png';
import card2 from '../../../assets/images/main/muchi-benefits/card2.png';
import card3 from '../../../assets/images/main/muchi-benefits/card3.png';
import { STORIES_ID } from '../../../assets/utils/constants';

const benefitsList = [
  {
    title: `Authentic
    Content`,
    // text: 'Experience heartfelt stories penned by&nbsp;genuine&nbsp;authors, rich&nbsp;with emotion and&nbsp;sincerity.',
    img: card1,
  },
  {
    title: `Exclusive
    Collaborations`,
    // text: 'Enjoy exclusive pieces from renowned writers,&nbsp;available only&nbsp;to&nbsp;Muchi members.',
    img: card2,
  },
  {
    title: `User-Friendly
    Interface`,
    // text: 'Navigate effortlessly through our elegantly designed platform, making your reading experience smooth and enjoyable.',
    img: card3,
  },
];

export default function MainAbout() {
  return (
    <div className="main-about" id={STORIES_ID} data-section>
      <StoryFormats />
      <LineDivider />
      <BenefitsList
        tag="Benefits of Muchi"
        tagColor="var(--benefits-tag-color-primary)"
        tagTextColor="var(--neutral-active)"
        list={benefitsList}
      />
      <Depths />
    </div>
  );
}
