import { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import './StoryFormats.css';
import { ArrowIcon } from '../../../assets/icons/icons';
import FormatsCard from './FormatsCard/FormatsCard';
import storiesImg from '../../../assets/images/main/story-formats/stories-card-img.png';
import seriesImg1 from '../../../assets/images/main/story-formats/series-card-img1.png';
import seriesImg2 from '../../../assets/images/main/story-formats/series-card-img2.png';
import seriesImg3 from '../../../assets/images/main/story-formats/series-card-img3.png';
import {
  DropIcon,
  RhombIcon,
  DotIcon,
  StarIcon,
} from '../../../assets/icons/icons';
import CardsSwiper from './CardsSwiper/CardsSwiper';
import {
  BASE_TIMING_FUNCTION,
  BASE_TRANSITION_DURATION,
} from '../../../assets/utils/constants';
import useWindowSize from '../../../assets/hooks/useWindowSize';

const storiesCard = {
  title: 'Individual Stories',
  text: 'For moments when you crave a&nbsp;quick escape, our standalone stories offer complete & satisfying experiences in&nbsp;just&nbsp;5&#8209;10&nbsp;minutes',
  img: storiesImg,
  icon: DropIcon,
  iconSize: { width: '20px', height: '26px' },
  iconColor: 'var(--cards-icon-color-first)',
  number: '',
};

const seriesCards = [
  {
    title: 'Series',
    text: `Embark on ongoing narratives, with&nbsp;each&nbsp;story unfolding like an episode in a&nbsp;captivating series. Follow the seasons & chapters, and let the characters' emotions resonate with your own`,
    img: seriesImg1,
    icon: RhombIcon,
    iconSize: { width: '26px', height: '26px' },
    iconColor: 'var(--cards-icon-color-second)',
    id: 1,
  },
  {
    title: 'Multi-Season',
    text: `Enjoy serialized stories that span across multiple seasons, offering deeper plot twists, evolving characters, and rich worlds to&nbsp;explore`,
    img: seriesImg2,
    icon: DotIcon,
    iconSize: { width: '24px', height: '24px' },
    iconColor: 'var(--cards-icon-color-second)',
    id: 2,
  },
  {
    title: 'Immerse Yourself',
    text: `Each story unfolds like an&nbsp;episode in&nbsp;a&nbsp;captivating series. Dive into layered storytelling where every chapter leaves you wanting more`,
    img: seriesImg3,
    icon: StarIcon,
    iconSize: { width: '30px', height: '30px' },
    iconColor: 'var(--cards-icon-color-third)',
    id: 3,
  },
];

const Path = (props) => (
  <motion.path
    initial="closed"
    transition={{
      duration: BASE_TRANSITION_DURATION,
      ease: BASE_TIMING_FUNCTION,
    }}
    layout
    {...props}
  />
);

export const arrowVariants = {
  closed: { opacity: 0 },
  opened: {
    opacity: 1,
    transition: {
      delay: BASE_TRANSITION_DURATION / 2,
    },
  },
};

const imgVariants = {
  closed: { x: '25%' },
  opened: {
    x: 0,
    transition: {
      duration: BASE_TRANSITION_DURATION,
      ease: BASE_TIMING_FUNCTION,
    },
  },
};

export default function StoryFormats() {
  const { width } = useWindowSize()
  const imgRef = useRef(null);
  const iconControls = useAnimation();
  const imgControls = useAnimation();
  const arrowControls = useAnimation();
  const imgInView = useInView(imgRef);

  useEffect(() => {
    if (imgInView) {
      const timeout = setTimeout(() => {
        iconControls.start('opened');
        imgControls.start('opened');
        arrowControls.start('opened');
      }, 1000); // Delay of 6000 milliseconds (6 seconds)
      return () => clearTimeout(timeout); // Clean up the timeout
    } else {
      // Immediately start 'closed' animations if imgInView is false
      iconControls.start('closed');
      imgControls.start('closed');
      arrowControls.start('closed');
    }
  }, [imgInView, iconControls, imgControls, arrowControls]);

  const containerCardsRef = useRef(null);
  const isContainerCardsInView = useInView(containerCardsRef, { amount: width > 600 ? 1 : 0.3 });
  return (
    <section className="story-formats" ref={containerCardsRef}>
      <div className="story-formats__title-block">
        <h2 className="story-formats__title">
          Story
          <br />
          Formats
        </h2>

        <div className="story-formats__img-box" ref={imgRef}>
          <div className="story-formats__ellipse" />
          <motion.svg
            className="story-formats__img-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="72"
            height="168"
            viewBox="0 0 72 168"
            fill="none"
            initial="closed"
            variants={imgVariants}
            animate={imgControls}
            layout
          >

            <Path
              className="story-formats__icon-big-el-fill"
              variants={{
                closed: {
                  d: 'M34 84C34 130.392 17 168 17 168C17 168 0 130.392 0 84C0 37.6081 17 0 17 0C17 0 34 37.6081 34 84Z',
                },
                opened: {
                  d: 'M72 84C72 130.392 36 168 36 168C36 168 0 130.392 0 84C0 37.6081 36 0 36 0C36 0 72 37.6081 72 84Z',
                },
              }}
              animate={iconControls}
            />
            <Path
              className="story-formats__icon-small-el-fill"
              variants={{
                closed: {
                  d: 'M31 84C31 123.212 17 155 17 155C17 155 3 123.212 3 84C3 44.7878 17 13 17 13C17 13 31 44.7878 31 84Z',
                },
                opened: {
                  d: 'M65 84C65 123.212 36 155 36 155C36 155 7 123.212 7 84C7 44.7878 36 13 36 13C36 13 65 44.7878 65 84Z',
                },
              }}
              animate={iconControls}
            />
          </motion.svg>


          <motion.div
            className="story-formats__arrow-box"
            initial="closed"
            variants={arrowVariants}
            animate={arrowControls}
            layout
          >
            {/* <ArrowIcon
              mainClassName="story-formats__arrow"
              fillClassName="story-formats__arrow-fill"
            /> */}
          </motion.div>
        </div>
      </div>

      <div className="story-formats__cards-block">
        <div className="story-formats__cards-box">
          <FormatsCard card={storiesCard} isStories />
        </div>
        <CardsSwiper cards={seriesCards} isInView={isContainerCardsInView} />
      </div>
    </section>
  );
}
