import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import throttle from 'lodash/throttle';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import GeneralInfo from '../../GeneralInfo/GeneralInfo';
import DotsDivider from '../../DotsDivider/DotsDivider';
import BenefitsList from '../../BenefitsList/BenefitsList';
import './MainHowWorks.css';
import icon from '../../../assets/images/main/info/icon.webp';
import card1 from '../../../assets/images/main/ai-benefits/card1.png';
import card2 from '../../../assets/images/main/ai-benefits/card2.png';
import card3 from '../../../assets/images/main/ai-benefits/card3.png';
import GetStarted from '../GetStarted/GetStarted';
import HowItWorks from '../HowItWorks/HowItWorks';
import {
  AI_ID,
  BASE_TIMING_FUNCTION,
  BASE_TRANSITION_DURATION,
} from '../../../assets/utils/constants';
import MainAi from '../MainAi/MainAi';

const benefitsList = [
  {
    title: `Personal
Connection`,
    text: 'Experience stories that resonate deeply&nbsp;with&nbsp;you, reflecting your desires&nbsp;and&nbsp;emotions',
    img: card1,
  },
  {
    title: `Privacy
& Confidentiality`,
    text: 'Your secrets are safe with us. The fantasies you&nbsp;share remain confidential, just between you and&nbsp;your personalized stories',
    img: card2,
  },
  {
    title: `Continuous
Exploration`,
    text: 'With endless possibilities, every&nbsp;new idea&nbsp;you&nbsp;share unlocks a&nbsp;fresh, exciting narrative&nbsp;to&nbsp;explore',
    img: card3,
  },
];

const imgVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: BASE_TRANSITION_DURATION,
      ease: BASE_TIMING_FUNCTION,
    },
  },
};

export default function MainHowWorks() {
  const [isStoryGenerated, setStoryGenerated] = useState(
    localStorage.getItem('storyGenerated')
      ? JSON.parse(localStorage.getItem('storyGenerated'))
      : false
  );
  const [isAiScrolled, setAiScrolled] = useState(false);

  const { width } = useWindowSize();
  const dotsListLength = width > 740 ? 24 : 40;
  
  const containerRef = useRef(null);
  const imgRef = useRef(null);
  
  const [cursorPos, setCursorPos] = useState({ x: -1000, y: -1000 }); // Initialize off-screen
  const dotRefs = useRef([]);

  // Initialize dotRefs.current as an array with the length of dotsListLength
  if (dotRefs.current.length !== dotsListLength) {
    dotRefs.current = Array(dotsListLength)
      .fill()
      .map((_, i) => dotRefs.current[i] || React.createRef());
  }

  const imgControls = useAnimation();
  const imgInView = useInView(imgRef, { amount: 0.1 });

  useEffect(() => {
    imgInView ? imgControls.start('visible') : imgControls.start('hidden');
  }, [imgInView, imgControls]);

  const MAX_SCALE = 3;
  const MIN_SCALE = 1;
  const MAX_DISTANCE = 150; // Adjust based on desired effect

  const getScale = (dotIndex) => {
    const dot = dotRefs.current[dotIndex].current;
    if (!dot) return MIN_SCALE;

    const containerRect = containerRef.current.getBoundingClientRect();
    const dotRect = dot.getBoundingClientRect();

    // Calculate the center position of the dot
    const dotX = dotRect.left + dotRect.width / 2 - containerRect.left;
    const dotY = dotRect.top + dotRect.height / 2 - containerRect.top;

    // Calculate distance from cursor
    const distance = Math.hypot(cursorPos.x - dotX, cursorPos.y - dotY);

    if (distance > MAX_DISTANCE) return MIN_SCALE;

    // Linearly interpolate scale between MAX_SCALE and MIN_SCALE
    const scale = MAX_SCALE - ((MAX_SCALE - MIN_SCALE) * distance) / MAX_DISTANCE;
    return scale;
  };

  // Throttle the mouse move handler for performance
  const handleMouseMove = throttle((e) => {
    const rect = containerRef.current.getBoundingClientRect();
    setCursorPos({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  }, 50); // Adjust the throttle delay as needed

  const handleMouseLeave = () => {
    // Reset cursor position when mouse leaves the container
    setCursorPos({ x: -1000, y: -1000 });
  };

  return (
    <div
      className="main-how-works"
      id={AI_ID}
      data-section
      ref={containerRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div className="main-how-works__container">
        <GeneralInfo
          title="Your Fantasy,<br />Unleashed by&nbsp;Our&nbsp;<span class='ai__span'>AI</span>"
          text="Simply share a&nbsp;glimpse of&nbsp;your desires or&nbsp;a&nbsp;brief description, and our sensual&nbsp;AI will weave a&nbsp;full-length story exclusively for&nbsp;you"
        >
          <div className="main-how-works__img-box" ref={imgRef}>
            <img className="main-how-works__icon" src={icon} alt="" />
            <div className="main-how-works__icon-bg" />
          </div>
        </GeneralInfo>
        <HowItWorks />
        {/* <DotsDivider /> */}
        <MainAi {...{ setStoryGenerated, isAiScrolled, setAiScrolled }} />
        <div className="main-how-works__benefits-box">
          <BenefitsList
            tag="Benefits of AI-Powered Stories"
            tagColor="var(--benefits-tag-color-secondary)"
            tagTextColor="var(--neutral-active)"
            list={benefitsList}
          />
        </div>
        <GetStarted {...{ isStoryGenerated, setAiScrolled }} />
      </div>
    </div>
  );
}
