import { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import './BenefitsList.css';
import BenefitCard from './BenefitCard/BenefitCard';
import { LIST_ITEMS_VARIANTS } from '../../assets/utils/constants';

export default function BenefitsList({
  tag,
  tagColor = 'var(--neutral-active)',
  tagTextColor = 'var(--neutral-contrast-active)',
  list,
}) {
  const itemsRef = useRef(null);
  const itemsControls = useAnimation();
  const itemsInView = useInView(itemsRef, { amount: 0.5 });

  useEffect(() => {
    itemsInView
      ? itemsControls.start('visible')
      : itemsControls.start('hidden');
  }, [itemsInView, itemsControls]);

  return (
    <section
      className="benefits-list"
      style={{ '--tag-color': tagColor, '--tag-text-color': tagTextColor }}
    >
      <div className="benefits-list__container">
        <div className="benefits-list__tag">
          <p className="benefits-list__tag-text">{tag}</p>
        </div>

        <ul className="benefits-list__list" ref={itemsRef}>
          {list.map((item, i) => (
            <li
              className="benefits-list__item"
              key={item.title}
            >
              <BenefitCard {...item} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
