import Heading from '../Heading/Heading';
import MainAbout from './MainAbout/MainAbout';
import MainHowWorks from './MainHowWorks/MainHowWorks';
import MainReviews from './MainReviews/MainReviews';
import { DotIcon } from '../../assets/icons/icons';
import './Main.css';
import { ABOUT_ID } from '../../assets/utils/constants';

export default function Main() {
  return (
    <main className="main">
      <div className="main__content">
        <Heading
          title="Ignite <br />Your Passion"
          text="Welcome to&nbsp;Muchi&mdash;where words awaken the senses and stir the soul. Dive into our exclusive collection of&nbsp;arousing stories, crafted by&nbsp;real authors to&nbsp;guide you on&nbsp;an&nbsp;intimate journey of&nbsp;self-discovery and fulfillment"
          dotElement={DotIcon}
          animBlockColor="var(--heading-neutral-primary)"
          dotColor="var(--heading-color-accent-primary)"
          elementsColor="var(--main-primary-active)"
          id={ABOUT_ID}
        />
        <MainAbout />
        <MainHowWorks />
        <MainReviews />
      </div>
    </main>
  );
}
