import { motion } from 'framer-motion';
import './ChatBubbleLoader.css';

const ChatBubbleLoader = ({ color = 'var(--neutral-active)' }) => {
  return (
    <div className="chat-bubble-loader">
      {[...Array(3)].map((_, i) => (
        <motion.div
          className="chat-bubble-loader__dot"
          key={i}
          style={{ backgroundColor: color }}
          animate={{
            opacity: [0.3, 1, 0.3],
            y: [0, -5, 0],
          }}
          transition={{
            duration: 0.8,
            repeat: Infinity,
            delay: i * 0.2,
          }}
        />
      ))}
    </div>
  );
};

export default ChatBubbleLoader;
