import { useRef, useState, useEffect, useContext } from 'react';
import validator from 'validator';
import { motion, AnimatePresence } from 'framer-motion';
import {
  AI_FORM_ID,
  BASE_TIMING_FUNCTION,
  BASE_TRANSITION_DURATION,
  ERROR_MESSAGE,
  SIGNUP_URL,
  TAP_BTN_SCALE,
  WAITLIST_POPUP_NAME,
} from '../../assets/utils/constants';
import './AiForm.css';
import { ArrowIcon, LogoIcon } from '../../assets/icons/icons';
import ChatBubbleLoader from './ChatBubbleLoader/ChatBubbleLoader';
import mainApi from '../../assets/api/MainApi';
import useWindowSize from '../../assets/hooks/useWindowSize';
import { parseStoryHTML } from '../../assets/utils/utils';
import tgFormApi from '../../assets/api/TgFormApi';
import { PopupContext } from '../../assets/contexts/popupContext';

const textList = [
  {
    default: 'let’s generate a story',
    focus: "What's your name?",
  },
  {
    greeting: {
      title: (name) => `Hello, ${name}!`,
      subtitle: 'Welcome to MUCHI Personal Stories Generator',
    },
    default: 'What is your email?',
  },
  'Tell me about your fantasies',
  (name) => `story for ${name}`,
];

const initialValues = {
  name: '',
  email: '',
  fantasies: '',
};

const initialValuesValidity = {
  name: { validState: false, errorMessage: '' },
  email: { validState: false, errorMessage: '' },
  fantasies: { validState: false, errorMessage: '' },
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.04, // Delay between each character
    },
  },
};

const storyContainerVariants = {
  hidden: { opacity: 0, height: '0' },
  visible: {
    opacity: 1,
    height: 'auto',
    transition: {
      height: {
        duration: BASE_TRANSITION_DURATION * 2,
        ease: BASE_TIMING_FUNCTION,
        type: 'tween',
      },
      staggerChildren: 0.02, // Delay between each character
    },
  },
};

const characterVariants = {
  hidden: { opacity: 0, y: 10, visibility: 'hidden' },
  visible: { opacity: 1, y: 0, visibility: 'visible' },
};

const contentVariants = {
  hidden: { opacity: 0, visibility: 'hidden' },
  visible: { opacity: 1, visibility: 'visible' },
};

export default function AiForm({ setStoryGenerated, isAiScrolled, setAiScrolled }) {
  const { openPopup } = useContext(PopupContext)
  const nameInput = useRef();
  const emailInput = useRef()
  const fantasiesInput = useRef()
  const { width } = useWindowSize();
  const [step, setStep] = useState(0);
  const [values, setValues] = useState(initialValues);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isGreetingShown, setIsGreetingShown] = useState(false);
  const [parsedStory, setParsedStory] = useState(null);
  const [isStoryLoading, setIsStoryLoading] = useState(false);
  const isFormValid =
    step === 0 ?
      Boolean(values.name) && valuesValidity.name.validState
      :
      step === 1 ?
        Boolean(values.email) && valuesValidity.email.validState
        :
        step === 2 ?
          Boolean(values.fantasies) && valuesValidity.fantasies.validState
          :
          false;
  const maxChars = width > 760 ? 600 : width > 550 ? 450 : 300;

  function handleChange(e) {
    const input = e.target;
    const name = input.name;
    const value = input.value;

    switch (name) {
      case 'email':
        if (!value) {
          setValuesValidity({
            ...valuesValidity,
            [name]: { validState: false, errorMessage: '' },
          });
        }

        if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setValuesValidity({
              ...valuesValidity,
              [name]: { validState: true, errorMessage: '' },
            });
          } else {
            setValuesValidity({
              ...valuesValidity,
              [name]: {
                validState: false,
                errorMessage: !e.target.validity.valid
                  ? e.target.validationMessage
                  : 'Invalid email',
              },
            });
          }
        }

        setValues({ ...values, [name]: value });
        break;

      default:
        setValues({ ...values, [name]: value });
        setValuesValidity({
          ...valuesValidity,
          [name]: { validState: Boolean(value), errorMessage: '' },
        });
    }
  }

  useEffect(() => {
    if (isAiScrolled) {
      focusOnInput();
      setAiScrolled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAiScrolled]);

  function focusOnInput() {
    if (nameInput?.current) {
      nameInput.current.focus()
    }

    if (emailInput?.current) {
      emailInput.current.focus()
    }

    if (fantasiesInput?.current) {
      fantasiesInput.current.focus()
    }
  }

  function nextStep() {
    setStep(step + 1);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!isFormValid) return;

    switch (step) {
      case 0:
        nextStep();
        setIsGreetingShown(true);
        setTimeout(() => {
          setIsGreetingShown(false);
          if (emailInput && emailInput.current) {
            emailInput.current.focus()
          }
        }, 4000);
        break;

      case 1:
        nextStep();
        setTimeout(() => {
          if (fantasiesInput && fantasiesInput.current) {
            fantasiesInput.current.focus()
          }
        }, 1000);


        break;

      case 2:
        nextStep();
        setIsStoryLoading(true);
        tgFormApi.sendContactForm({
          name: `READER - ` + values.name,
          email: values.email,
          story: values.fantasies,
        })
          .then(() => {

          })
          .catch(() => {

          })
        mainApi
          .createStory({ text: values.fantasies })
          .then((res) => {
            const story = parseStoryHTML(res.data, maxChars);
            setParsedStory(story);
            setStoryGenerated(true);
            localStorage.setItem('storyGenerated', true);
          })
          .catch((err) => {
            console.log(err);
            const story = parseStoryHTML(ERROR_MESSAGE, maxChars);
            setParsedStory(story);
          })
          .finally(() => {
            setIsStoryLoading(false);
          });
        break;

      default:
        nextStep();
    }
  }

  const [isNameTriggered, setNameTriggered] = useState(false)

  return (
    <div className="ai-form" id={AI_FORM_ID}>
      <div className="ai-form__container">
        <ul className="ai-form__heading">
          <button
            className="ai-form__logo-btn"
            type="button"
            onClick={focusOnInput}
          >
            <LogoIcon
              mainClassName="ai-form__logo"
              backgroundClassName="ai-form__logo-background"
              borderClassName="ai-form__logo-border"
              fillClassName="ai-form__logo-fill"
            />
          </button>
          {Object.values(values).map(
            (value, i) =>
              i < step && (
                <motion.li
                  className="ai-form__data-item"
                  key={i}
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                >
                  <p className="ai-form__data-value">
                    {value.split('').map((char, index) => (
                      <motion.span key={index} variants={characterVariants}>
                        {char === ' ' ? '\u00A0' : char}
                      </motion.span>
                    ))}
                  </p>
                </motion.li>
              )
          )}
        </ul>

        <div className="ai-form__content">
          <AnimatePresence mode="wait" initial={false}>
            {step === 0 &&
              (isNameFocused || values.name ? (
                <motion.h4
                  className="ai-form__title"
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  key="name-focus"
                >
                  {textList[step].focus.split('').map((char, index) => (
                    <motion.span key={index} variants={characterVariants}>
                      {char}
                    </motion.span>
                  ))}
                </motion.h4>
              ) : (
                <motion.h4
                  className="ai-form__title"
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  key="name-default"
                >
                  {textList[step].default.split('').map((char, index) => (
                    <motion.span key={index} variants={characterVariants}>
                      {char}
                    </motion.span>
                  ))}
                </motion.h4>
              ))}

            {step === 1 &&
              (isGreetingShown ? (
                <motion.div
                  className="ai-form__text-box"
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  key="greeting"
                >
                  <h4 className="ai-form__title">
                    {textList[step].greeting
                      .title(values.name)
                      .split('')
                      .map((char, index) => (
                        <motion.span key={index} variants={characterVariants}>
                          {char}
                        </motion.span>
                      ))}
                  </h4>
                  <p className="ai-form__subtitle">
                    {textList[step].greeting.subtitle
                      .split('')
                      .map((char, index) => (
                        <motion.span key={index} variants={characterVariants}>
                          {char}
                        </motion.span>
                      ))}
                  </p>
                </motion.div>
              ) : (
                <motion.h4
                  className="ai-form__title"
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  key="email-default"
                >
                  {textList[step].default.split('').map((char, index) => (
                    <motion.span key={index} variants={characterVariants}>
                      {char}
                    </motion.span>
                  ))}
                </motion.h4>
              ))}

            {step === 2 && (
              <motion.h4
                className="ai-form__title ai-form__title_width_restricted"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                key="fantasies"
              >
                {textList[step].split('').map((char, index) => (
                  <motion.span key={index} variants={characterVariants}>
                    {char}
                  </motion.span>
                ))}
              </motion.h4>
            )}

            {step === 3 && (
              <motion.div
                className="ai-form__content-box"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                key="result"
              >
                <h4 className="ai-form__title ai-form__title_size_medium">
                  {textList[step](values.name)
                    .split('')
                    .map((char, index) => (
                      <motion.span key={index} variants={characterVariants}>
                        {char}
                      </motion.span>
                    ))}
                </h4>
                {isStoryLoading || !parsedStory ? (
                  <motion.div
                    className="ai-form__loader"
                    variants={contentVariants}
                  >
                    <ChatBubbleLoader />
                  </motion.div>
                ) : (
                  <motion.div
                    className="ai-form__story-box"
                    variants={storyContainerVariants}
                  >
                    {parsedStory.elements.map((element) => {
                      const renderContent = (content) => {
                        if (typeof content === 'string') {
                          return content.split('').map((char, index) => (
                            <motion.span
                              key={index}
                              variants={characterVariants}
                            >
                              {char}
                            </motion.span>
                          ));
                        }

                        return content.map((child, index) => {
                          switch (child.type) {
                            case 'text':
                              return renderContent(child.content);
                            case 'em':
                              return (
                                <em key={index}>
                                  {child.children.map((c) =>
                                    renderContent(c.content)
                                  )}
                                </em>
                              );
                            case 'b':
                              return (
                                <b key={index}>
                                  {child.children.map((c) =>
                                    renderContent(c.content)
                                  )}
                                </b>
                              );
                            default:
                              return null;
                          }
                        });
                      };

                      switch (element.type) {
                        case 'h2':
                          return (
                            <h2
                              className="ai-form__title ai-form__title_size_small"
                              key={element.key}
                            >
                              {element.children.map((child) =>
                                renderContent(child.content)
                              )}
                            </h2>
                          );
                        case 'p':
                          return (
                            <p className="ai-form__text" key={element.key}>
                              {element.children.map((child) => {
                                if (child.type === 'text') {
                                  return renderContent(child.content);
                                }
                                return renderContent([child]);
                              })}
                            </p>
                          );
                        default:
                          return null;
                      }
                    })}
                    {parsedStory.isTextTruncated && (
                      <motion.button
                        className="ai-form__more-btn"
                        // href={SIGNUP_URL}
                        onClick={() => { openPopup(WAITLIST_POPUP_NAME) }}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: TAP_BTN_SCALE }}
                        variants={contentVariants}
                      >
                        Read More...
                      </motion.button>
                    )}
                  </motion.div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      <AnimatePresence>
        {step !== 3 && (
          <motion.form
            className={`ai-form__form ${!isNameTriggered ? 'ai-form__form_first-not-triggered' : ''
              }`}
            onSubmit={handleSubmit}
            onClick={focusOnInput}
            initial={{ opacity: 1, visibility: 'visible', display: 'flex' }}
            exit={{ opacity: 0, visibility: 'hidden', display: 'none' }}
            transition={{ duration: 0.15 }}
            key="form"
          >
            {step === 0 && (
              <input
                className="ai-form__input"
                name="name"
                ref={nameInput}
                id="name"
                maxLength={256}
                type="text"
                placeholder="What's your name?"
                value={values.name || ''}
                autoComplete="off"
                onChange={handleChange}
                onFocus={() => {
                  setNameTriggered(true);
                  setIsNameFocused(true);
                }}
                onBlur={() => setIsNameFocused(false)}
              />
            )}

            {step === 1 && (
              <input
                className="ai-form__input"
                ref={emailInput}
                name="email"
                id="email"
                maxLength={256}
                type="email"
                placeholder="What is your email?"
                value={values.email || ''}
                onChange={handleChange}
              />
            )}

            {step === 2 && (
              <input
                className="ai-form__input"
                name="fantasies"
                id="fantasies"
                type="text"
                ref={fantasiesInput}
                maxLength={256}
                placeholder="Share your wildest desires..."
                value={values.fantasies || ''}
                autoComplete="off"
                onChange={handleChange}
              />
            )}

            <div
              className="ai-form__submit-box"
              onClick={(e) => e.stopPropagation()}
            >
              <motion.button
                className={`ai-form__submit-btn ${!isFormValid ? 'ai-form__submit-btn_disabled' : ''
                  }`}
                type="submit"
                disabled={!isFormValid}
                whileHover={{ opacity: 0.85 }}
                whileTap={{ scale: TAP_BTN_SCALE }}
              >
                <ArrowIcon
                  mainClassName="ai-form__submit-icon"
                  fillClassName="ai-form__submit-icon-fill"
                />
              </motion.button>
            </div>
          </motion.form>
        )}
      </AnimatePresence>
    </div>
  );
}
