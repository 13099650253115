import { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import './FormatsCard.css';
import {
  BASE_TIMING_FUNCTION,
  BASE_TRANSITION_DURATION,
} from '../../../../assets/utils/constants';

const iconXVariants = {
  hidden: { x: '-110%', opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: BASE_TRANSITION_DURATION,
      ease: BASE_TIMING_FUNCTION,
      delay: 2,
    },
  },
};

const iconYVariants = {
  hidden: { y: '-110%' },
  visible: {
    y: 0,
    transition: {
      duration: BASE_TRANSITION_DURATION,
      ease: BASE_TIMING_FUNCTION,
    },
  },
};

export default function FormatsCard({ card, isStories, activeIndex, index, swiperInstance}) {
  const {
    title,
    text,
    img,
    icon,
    number,
    iconSize = { width: '26px', height: '26px' },
    iconColor = 'var(--main-primary-active)',
  } = card;
  const iconRef = useRef(null);
  const iconXControls = useAnimation();
  const iconYControls = useAnimation();
  // const iconInView = useInView(iconRef, { amount: 1 });

  useEffect(() => {
    activeIndex === index
      ? iconXControls.start('visible')
      : iconXControls.start('hidden');
  }, [iconXControls, activeIndex, index]);

  // useEffect(() => {
  //   iconInView ? iconYControls.start('visible') : iconYControls.start('hidden');
  // }, [iconInView, iconYControls]);

  return (
    <div
      className={`formats-card ${isStories ? 'formats-card_type_stories' : ''}`}
      style={{
        '--icon-width': iconSize.width,
        '--icon-height': iconSize.height,
        '--icon-color': iconColor,
      }}
      onClick={()=>{
        if(activeIndex !== index){
          console.log({swiperInstance})
          swiperInstance.slideTo(index)
        }
      }}
    >
      <div className="formats-card__img-box" ref={iconRef}>
        <img className="formats-card__img" src={img} alt={title} />
        <motion.div
          className={`formats-card__icon-box ${isStories ? 'formats-card__icon-box_type_stories' : ''
            }`}
          initial="hidden"
          animate={isStories ? iconYControls : iconXControls}
          variants={isStories ? iconYVariants : iconXVariants}
          layout
        >
          {icon({
            mainClassName: 'formats-card__icon',
            fillClassName: 'formats-card__icon-fill',
          })}
        </motion.div>
      </div>

      {number && <p className="formats-card__number">{number}</p>}

      <div className="formats-card__text-box">
        <p
          className="formats-card__title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className="formats-card__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
}
