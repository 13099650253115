export const PRIVACY_POLICY_TITLE = `Privacy
Policy`;

export const PRIVACY_POLICY_TEXT = `
<section>
<h2>Introduction</h2>
<p>This Privacy Policy explains how Muchi collects, uses, and protects your personal information when you access or use our platform. By using Muchi, you consent to the practices described in this policy.</p>
</section>

<section>
<h2>Information We Collect</h2>
<p>We collect the following types of information:</p>
<ul>
  <li><strong>Account Information:</strong> When you create an account, we collect your name, email address, and password.</li>
  <li><strong>Usage Data:</strong> We collect information about your interactions with our platform, such as stories you read, search queries, and your preferences.</li>
  <li><strong>Payment Information:</strong> When purchasing Muchi Gems or other services, we collect payment details to process transactions securely.</li>
  <li><strong>Device and Technical Data:</strong> Information about your device, browser, and IP address is collected to improve platform performance and security.</li>
</ul>
</section>

<section>
<h2>How We Use Your Information</h2>
<ul>
  <li>To provide and improve the platform's features and services.</li>
  <li>To personalize your experience by recommending stories or authors you may like.</li>
  <li>To process payments and provide customer support.</li>
  <li>To ensure platform security and prevent fraudulent activity.</li>
  <li>To comply with legal obligations and resolve disputes.</li>
</ul>
</section>

<section>
<h2>Sharing Your Information</h2>
<p>We do not sell your personal data. We may share your information in the following situations:</p>
<ul>
  <li><strong>With Service Providers:</strong> Third-party vendors assisting with payment processing, hosting, and analytics.</li>
  <li><strong>For Legal Reasons:</strong> If required by law or in response to valid legal requests.</li>
  <li><strong>With Your Consent:</strong> When you explicitly agree to share information with partners or third parties.</li>
</ul>
</section>

<section>
<h2>Your Rights</h2>
<p>You have the right to:</p>
<ul>
  <li>Access and update your personal information through your account settings.</li>
  <li>Request deletion of your data by contacting <a href="mailto:privacy@muchi.io">privacy@muchi.io</a>.</li>
  <li>Withdraw consent for specific data uses, such as marketing communications.</li>
</ul>
</section>

<section>
<h2>Data Security</h2>
<p>We implement robust security measures to protect your information, including encryption and secure data storage. However, no method of data transmission is 100% secure, and we cannot guarantee absolute security.</p>
</section>

<section>
<h2>Changes to this Privacy Policy</h2>
<p>We may update this Privacy Policy periodically. Any changes will be posted on this page with an updated effective date.</p>
</section>

<section>
<h2>Contact Us</h2>
<p>If you have any questions about this policy or your data, please contact us at <a href="mailto:privacy@muchi.io">privacy@muchi.io</a>.</p>
</section>
`;
