export const CONTACT_TITLE = `Contact`;

export const CONTACT_TEXT = `
      <h2>Need Help?</h2>
      <p>If you have questions about your account, need help accessing stories, or want to report an issue, our support team is here for you.</p>
      <h2>Contact Information</h2>
      <ul>
          <li>Email: <a href="mailto:support@muchi.io">support@muchi.io</a></li>
      </ul>
`;

