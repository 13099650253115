export const TERMS_OF_SERVICE_TITLE = `Terms
of Service`;

export const TERMS_OF_SERVICE_TEXT = `
<section>
      <h2>Introduction</h2>
      <p>Welcome to Muchi! By accessing or using our platform, you agree to be bound by these Terms of Service. Please read them carefully.</p>
    </section>
    
    <section>
      <h2>Eligibility</h2>
      <p>You must be at least 18 years old to create an account or use the platform. By using Muchi, you represent that you meet this age requirement.</p>
    </section>
    
    <section>
      <h2>User Responsibilities</h2>
      <ul>
        <li>You agree to provide accurate information during registration and keep your account credentials secure.</li>
        <li>You must not post or share content that is illegal, harmful, or violates the rights of others.</li>
        <li>You are responsible for all activity under your account.</li>
      </ul>
    </section>
    
    <section>
      <h2>Content Ownership</h2>
      <p>Authors retain ownership of the stories they publish. By sharing content on Muchi, you grant us a non-exclusive, worldwide license to display, distribute, and promote your work within the platform.</p>
    </section>
    
    <section>
      <h2>Purchases and Refunds</h2>
      <ul>
        <li>All Muchi Gems purchases are final and non-refundable.</li>
        <li>If you encounter technical issues with your purchase, contact <a href="mailto:support@muchi.io">support@muchi.io</a>.</li>
      </ul>
    </section>
    
    <section>
      <h2>Prohibited Activities</h2>
      <ul>
        <li>Using the platform to engage in illegal or harmful activities.</li>
        <li>Sharing or redistributing paid content without permission.</li>
        <li>Hacking, scraping, or interfering with the platform's functionality.</li>
      </ul>
    </section>
    
    <section>
      <h2>Termination</h2>
      <p>We reserve the right to suspend or terminate your account for violating these terms or engaging in harmful behavior. Termination does not absolve you from any outstanding obligations, such as payments.</p>
    </section>
    
    <section>
      <h2>Changes to Terms</h2>
      <p>We may modify these Terms of Service periodically. Continued use of the platform after changes indicates acceptance of the updated terms.</p>
    </section>
    
    <section>
      <h2>Limitation of Liability</h2>
      <p>Muchi is not responsible for any damages arising from the use of the platform. Use the platform at your own risk.</p>
    </section>
    
    <section>
      <h2>Contact Us</h2>
      <p>For questions about these terms, contact us at <a href="mailto:terms@muchi.io">terms@muchi.io</a>.</p>
    </section>
`;
