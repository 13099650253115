import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Mousewheel, Navigation, Autoplay } from 'swiper/modules';
import './MainReviews.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { ArrowIcon } from '../../../assets/icons/icons';
import slide1 from '../../../assets/images/main/reviews/slide1.png';
import slide2 from '../../../assets/images/main/reviews/slide2.png';
import slide3 from '../../../assets/images/main/reviews/slide3.png';
import slide4 from '../../../assets/images/main/reviews/slide4.png';
import ReviewCard from './ReviewCard/ReviewCard';

const reviews = [
  {
    text: 'The AI-generated story felt so&nbsp;personal and intimate. It&nbsp;was as&nbsp;if&nbsp;it&nbsp;knew exactly what&nbsp;I yearned for, it&nbsp;sent me&nbsp;to&nbsp;orbit, the place, where&nbsp;I belong&nbsp;to...',
    name: 'Emma W.',
    img: slide1,
    id: 1,
  },
  {
    text: 'I&nbsp;was amazed at&nbsp;how the&nbsp;AI brought my&nbsp;simple idea to&nbsp;life in&nbsp;such a&nbsp;beautiful way. Damned sexy!',
    name: 'Olivia M.',
    img: slide2,
    id: 2,
  },
  {
    text: 'The personalized story created by&nbsp;Jeremy (yes, I&nbsp;gave him a&nbsp;name!) felt like a&nbsp;secret diary written just for&nbsp;me',
    name: 'Megan J. T.',
    img: slide3,
    id: 3,
  },
  {
    text: "I&rsquo;ve experienced something like that before, but the way&nbsp;AI captured my&nbsp;thoughts made me&nbsp;kinda &laquo;breathe&raquo; again",
    name: 'Mia S.',
    img: slide4,
    id: 4,
  },
];

export default function MainReviews() {
  return (
    <section
      className="main-reviews"
      style={{
        '--swiper-pagination-bottom': 0,
        '--swiper-pagination-bullet-horizontal-gap': '6px',
        '--swiper-pagination-color': 'var(--reviews-neutral-primary)',
        '--swiper-pagination-bullet-inactive-color': 'var(--reviews-neutral-primary)',
        '--swiper-pagination-bullet-inactive-opacity': 0.22,
      }}
    >
      <h2 className="main-reviews__title">User reviews</h2>

      <button
        className="main-reviews__nav-button main-reviews__button-prev"
        type="button"
      >
        <ArrowIcon
          mainClassName="main-reviews__nav-icon"
          fillClassName="main-reviews__nav-icon-fill"
        />
      </button>

      <Swiper
        className="main-reviews__swiper"
        slidesPerView={1.3}
        spaceBetween={30}
        loop={true}
        // autoplay={true}
        mousewheel={{ forceToAxis: true }}
        navigation={{
          nextEl: '.main-reviews__button-next',
          prevEl: '.main-reviews__button-prev',
        }}
        pagination={{ clickable: true }}
        modules={[Mousewheel, Navigation, Pagination]}
        breakpoints={{
          741: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1101: {
            slidesPerView: 2,
            spaceBetween: 292,
          },
        }}
      >
        {reviews?.map((slide) => (
          <SwiperSlide key={slide.id}>
            <ReviewCard {...slide} />
          </SwiperSlide>
        ))}
      </Swiper>

      <button
        className="main-reviews__nav-button main-reviews__button-next"
        type="button"
      >
        <ArrowIcon
          mainClassName="main-reviews__nav-icon"
          fillClassName="main-reviews__nav-icon-fill"
        />
      </button>
    </section>
  );
}
