export function parseNode(node) {
  if (node.nodeType === 3) {
    // Text node
    return { type: 'text', content: node.textContent };
  } else if (node.nodeType === 1) {
    // Element node
    const children = Array.from(node.childNodes).map(parseNode);
    return {
      type: node.tagName.toLowerCase(),
      children,
    };
  }
  return null;
}

export function parseStoryHTML(htmlString, maxChars = 700) {
  // Handle plain text input
  if (!htmlString.includes('<')) {
    const truncated = htmlString.length > maxChars;
    let slicedText = htmlString;

    if (truncated) {
      let cutoffIndex = Math.min(maxChars, htmlString.length);
      const nextPeriod = htmlString.indexOf('.', cutoffIndex);
      if (nextPeriod !== -1 && nextPeriod - cutoffIndex < 50) {
        cutoffIndex = nextPeriod + 1;
      }
      slicedText = htmlString.slice(0, cutoffIndex);
    }

    return {
      elements: [
        {
          type: 'p',
          children: [{ type: 'text', content: slicedText }],
          key: 'element-0',
        },
      ],
      isTextTruncated: truncated,
    };
  }

  // Create a temporary div to parse HTML
  const div = document.createElement('div');

  // Get plain text to count characters
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  const plainText = tempDiv.textContent;

  // Find a good breaking point (after a period) near our target length
  let cutoffIndex = Math.min(maxChars, plainText.length);
  const nextPeriod = plainText.indexOf('.', cutoffIndex);
  if (nextPeriod !== -1 && nextPeriod - cutoffIndex < 50) {
    // Look ahead up to 50 chars for a period
    cutoffIndex = nextPeriod + 1;
  }

  // Slice the HTML content
  let slicedHTML = htmlString;
  if (cutoffIndex < plainText.length) {
    // Find the corresponding position in the HTML
    let charCount = 0;
    let htmlIndex = 0;

    while (charCount < cutoffIndex && htmlIndex < htmlString.length) {
      if (htmlString[htmlIndex] === '<') {
        // Skip tags
        while (htmlIndex < htmlString.length && htmlString[htmlIndex] !== '>') {
          htmlIndex++;
        }
      } else {
        charCount++;
      }
      htmlIndex++;
    }

    // Find the end of the current tag if we're in the middle of one
    while (htmlIndex < htmlString.length && htmlString[htmlIndex - 1] !== '>') {
      htmlIndex++;
    }

    slicedHTML = htmlString.slice(0, htmlIndex);

    // Close any unclosed tags
    const matches = slicedHTML.match(/<(\w+)[^>]*>/g) || [];
    const closingTags = matches
      .reverse()
      .map((tag) => tag.match(/<(\w+)[^>]*>/)[1])
      .filter((tag) => !slicedHTML.includes(`</${tag}>`))
      .map((tag) => `</${tag}>`)
      .join('');

    slicedHTML += closingTags;
  }

  div.innerHTML = slicedHTML;

  const isTextTruncated = cutoffIndex < plainText.length;

  return {
    elements: Array.from(div.childNodes)
      .map((node, index) => ({ ...parseNode(node), key: `element-${index}` }))
      .filter(Boolean),
    isTextTruncated,
  };
}
