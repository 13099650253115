import { TG_FORM_API_URL } from '../utils/constants';

class TgFormApi {
    constructor({ baseUrl }) {
        this._baseUrl = baseUrl;
    }

    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            const statusCode = res.status;
            return res.json().then((res) => {
                return Promise.reject({
                    statusCode: statusCode,
                    message: res.msg,
                    detail: res.detail,
                });
            });
        }
    }

    sendContactForm({ name, email, story }) {
        let params = {};
        if (name) params.name = name;
        if (email) params.email = email;
        if (story) params.story = story;

        return fetch(`${this._baseUrl}MUCHI_LANDING/?` + new URLSearchParams(params), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(this._checkResponse);
    }

    sendWaitlist({ email }) {
        let params = {};
        if (email) params.email = "READER " + email;

        return fetch(`${this._baseUrl}MUCHI_WAITLIST/?` + new URLSearchParams(params), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(this._checkResponse);
    }
}

const tgFormApi = new TgFormApi({
    baseUrl: TG_FORM_API_URL,
});

export default tgFormApi;