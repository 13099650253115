import { useEffect, useRef, useState } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, EffectCards, Mousewheel, Navigation } from 'swiper/modules';
import FormatsCard from '../FormatsCard/FormatsCard';
import './CardsSwiper.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import { ArrowIcon, DotIcon } from '../../../../assets/icons/icons';
import { DOTS_VARIANTS } from '../../../../assets/utils/constants';

export default function CardsSwiper({ cards, isInView }) {
  const width = window.innerWidth;
  const [swiperInstance, setSwiperInstance] = useState(null);
  const dotsRef = useRef(null);
  const dotsControls = useAnimation();
  const dotsInView = useInView(dotsRef, { amount: 1 });
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    dotsInView ? dotsControls.start('visible') : dotsControls.start('hidden');
  }, [dotsInView, dotsControls]);

  const [arrowDirectionNext, setArrowDirectionNext] = useState(true)
  useEffect(() => {
    if (activeIndex === 0) {
      setArrowDirectionNext(true); // При первом слайде направляем вперед
    } else if (activeIndex === cards.length - 1) {
      setArrowDirectionNext(false); // При последнем слайде направляем назад
    }
  }, [activeIndex, cards.length]);




  useEffect(() => {
    if (swiperInstance && swiperInstance.autoplay) {
      console.log({isInView})
      if (isInView) {
        swiperInstance.autoplay.start();
      } else {
        swiperInstance.autoplay.stop();
      }
    }
  }, [isInView, swiperInstance]);

  return (
    <div
      className="cards-swiper"
      style={{
        '--swiper-pagination-top': '0',
      }}
    >
      <Swiper
        className="cards-swiper__swiper"
        slidesPerView={1}
        grabCursor={true}
        pagination={{ type: 'fraction' }}
        autoplay={{
          delay: 6000,
          disableOnInteraction: true,
        }}
        mousewheel={true}
        effect="cards"
        speed={800}
        cardsEffect={{
          perSlideOffset: width > 740 ? 12 : 8,
          perSlideRotate: 0,
        }}

        onSwiper={(swiper) => {
          setSwiperInstance(swiper)
          swiper.autoplay.stop();
        }}
        modules={[Pagination, Autoplay, EffectCards, Mousewheel, Navigation]}
        onSlideChangeTransitionEnd={(e) => setActiveIndex(e.activeIndex)}
      >
        {cards?.map((card, i) => (
          <SwiperSlide className="cards-swiper__slide" key={card.id}>
            <FormatsCard card={card} activeIndex={activeIndex} index={i} swiperInstance={swiperInstance} />
          </SwiperSlide>
        ))}
      </Swiper>

      <ul className="cards-swiper__dots" ref={dotsRef}>
        {/* {Array.from({ length: 3 }).map((_, i) => (
          <motion.li
            className="cards-swiper__dot"
            key={i}
            custom={i}
            initial="hidden"
            animate={dotsControls}
            variants={DOTS_VARIANTS}
            layout
          >
            <DotIcon
              mainClassName="cards-swiper__dot-icon"
              fillClassName="cards-swiper__dot-icon-fill"
            />
          </motion.li>
        ))} */}
        <button
          className={`cards-swiper__swiper-button ${!arrowDirectionNext ? 'cards-swiper__swiper-button-prev' : 'cards-swiper__swiper-button-next'}`}
          type="button"
          onClick={() => {
            !arrowDirectionNext ?
              swiperInstance.slidePrev()
              :
              swiperInstance.slideNext()
          }}
        >
          <ArrowIcon
            mainClassName="main-reviews__nav-icon"
            fillClassName="main-reviews__nav-icon-fill"
          />
        </button>
      </ul>
    </div>
  );
}
