// Depths.js

import React, { useContext, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './Depths.css';
import icon from '../../../assets/images/main/depths/icon.png';
import { PopupContext } from '../../../assets/contexts/popupContext';
import { WAITLIST_POPUP_NAME } from '../../../assets/utils/constants';

export default function Depths() {
  const controls = useAnimation();
const {openPopup} = useContext(PopupContext)
  useEffect(() => {
    let isMounted = true;

    const animatePulse = async () => {
      // Wait for component to mount
      await new Promise((resolve) => setTimeout(resolve, 100));

      while (isMounted) {
        const randomScale = Math.max(1.4, 1 + Math.random() * 4);
        const randomDuration = Math.max(1.4, 1 + Math.random() * 4);

        try {
          await controls.start({
            scale: [1, randomScale, 1],
            opacity: [1, 0.9, 1],
            transition: {
              duration: randomDuration,
              ease: 'easeInOut',
            },
          });
        } catch (error) {
          console.error(error);
        }

        // Add a small delay between animations
        await new Promise((resolve) => setTimeout(resolve, 10));
      }
    };

    animatePulse();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [controls]);

  return (
    <section className="depths">
      <div className="depths__container">
        <div className="depths__content">
          <div className="depths__img-box">
            <img className="depths__img" src={icon} alt="Depth Icon" />
          </div>

          <div className="depths__text-box" onClick={()=>{openPopup(WAITLIST_POPUP_NAME)}}>
            <p className="depths__title">
              Discover New Depths of&nbsp;Pleasure with Muchi
            </p>
            <p className="depths__text">
              Dive into a world of intimate stories crafted just for you
            </p>
          </div>
        </div>

        <div className="depths__circle">
          <motion.div
            className="depths__circle-inner"
            initial={{ scale: 1, opacity: 1 }}
            animate={controls}
          />
        </div>
      </div>
    </section>
  );
}
