import React, { useEffect, useState, forwardRef } from "react";
import "./Input.css";

const Input = forwardRef(
  (
    {
      index,
      label,
      boxType,
      value,
      name,
      type,
      inputMode = "text",
      handleChange,
      isRequired,
      readOnly = false,
      icon,
      handleVisibility,
      error,
      isTextArea,
      maxRows,
      minRows,
      onKeyDown,
      handleFocus,
      preloader,
      isCorrectState,
      notCorrectAddress,
      hint,
      placeholder,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      Boolean(value) ? setIsFocused(true) : setIsFocused(false);
    }, [value]);

    function handleFocused() {
      setIsFocused(true);
    }

    function handleUnfocused(evt) {
      const value = evt.target.value;
      Boolean(value) ? setIsFocused(true) : setIsFocused(false);
    }

    function handlePopupVisibility(evt) {
      handleVisibility(true, evt);
    }

    function handlePopupBlur(evt) {
      handleVisibility(false, evt);
    }

    function handleInputChange(evt) {
      index !== undefined ? handleChange(evt, index) : handleChange(evt);
    }

    function handlePasswordVisibility() {
      handleVisibility(name);
    }

    return (
      <div
        className={`custom-input ${
          preloader || isCorrectState ? "custom-input__preloader" : ""
        } custom-input_type_${boxType ? boxType : name} ${
          isFocused || Boolean(value) ? "custom-input_focused" : ""
        } ${readOnly ? "custom-input_disabled" : ""}`}
      >
        <label className="custom-input__label" htmlFor={name}>
          {label}
        </label>

        {isTextArea ? (
          ""
        ) : (
          <input
            className={`custom-input__input ${
              icon ? "custom-input__input_type_with-icon" : ""
            } ${
              Boolean(value) ? "custom-input__input_valid" : ""
            } ${
              error !== undefined && Boolean(error.errorMessage)
                ? "custom-input__input_error"
                : ""
            }`}
            id={name}
            name={name}
            type={type}
            inputMode={inputMode}
            value={value || ""}
            required={isRequired}
            readOnly={readOnly}
            placeholder={placeholder}
            ref={ref} // <-- This line forwards the ref to the input element
            onChange={handleInputChange}
            onClick={!readOnly ? handleFocused : undefined}
            onFocus={!readOnly ? handleFocused : undefined}
            onBlur={handleUnfocused}
            onKeyDown={(e) => {
              if (onKeyDown) {
                onKeyDown(e);
              }
            }}
          />
        )}

        {error !== undefined && Boolean(error.errorMessage) && !hint ? (
          <span
            className="custom-input__error"
            dangerouslySetInnerHTML={{
              __html: error.errorMessage,
            }}
          ></span>
        ) : null}
        {!error && hint !== undefined && Boolean(hint) ? (
          <span
            className="custom-input__hint"
            dangerouslySetInnerHTML={{
              __html: hint,
            }}
          ></span>
        ) : null}

        {name.toLowerCase().includes("password") && icon ? (
          <button
            className="custom-input__password-button"
            type="button"
            aria-label="Toggle password visibility"
            onClick={handlePasswordVisibility}
          >
            {icon}
          </button>
        ) : icon ? (
          <div className="custom-input__icon">{icon}</div>
        ) : null}
      </div>
    );
  }
);

export default Input;
