import { FacebookIcon, InstagramIcon, TwitterIcon } from '../icons/icons';

export const MAIN_URL = 'https://app.muchi.io/v2';
export const TG_FORM_API_URL = 'https://tg-function.yc-cabatest.ru/CABA_NOTIFIER/'

export const CREATORS_LINK = 'https://creators.muchi.io'

export const MOBILE_MENU_ID = 'mobile-menu';
export const ABOUT_ID = 'about';
export const STORIES_ID = 'stories';
export const AI_ID = 'ai';
export const AI_FORM_ID = 'ai-form';

export const WAITLIST_POPUP_NAME = 'waitlist'

export const HOME_LINK = {
  label: 'Home',
  path: '/',
};
export const ABOUT_LINK = {
  label: 'About',
  path: ABOUT_ID,
};
export const STORIES_LINK = {
  label: 'Stories',
  path: STORIES_ID,
};
export const AI_LINK = {
  label: 'AI',
  path: AI_ID,
};
export const HEADER_NAV_LIST = [ABOUT_LINK, STORIES_LINK, AI_LINK];

export const FOOTER_CONTACT_LINK = {
  label: 'Contact',
  path: '/contact',
};
export const FOOTER_HELP_LINK = {
  label: 'Help Center',
  path: '/help-center',
};
export const FOOTER_PRIVACY_LINK = {
  label: 'Privacy Policy',
  path: '/privacy-policy',
};
export const FOOTER_SERVICE_LINK = {
  label: 'Terms of Service',
  path: '/terms-of-service',
};
export const FOOTER_NAV_LIST = [
  FOOTER_CONTACT_LINK,
  FOOTER_HELP_LINK,
  FOOTER_PRIVACY_LINK,
  FOOTER_SERVICE_LINK,
];

export const FACEBOOK_LINK = {
  label: 'Facebook',
  link: undefined,
  icon: FacebookIcon,
};
export const INSTAGRAM_LINK = {
  label: 'Instagram',
  link: undefined,
  icon: InstagramIcon,
};
export const TWITTER_LINK = {
  label: 'X',
  link: undefined,
  icon: TwitterIcon,
};
export const SOCIAL_LINKS = [FACEBOOK_LINK, INSTAGRAM_LINK, TWITTER_LINK];

export const SIGNUP_URL = '/#';
export const LOGIN_URL = '/#';

export const TAP_BTN_SCALE = 0.95;
export const BASE_TRANSITION_DURATION = 0.8;
export const BASE_TIMING_FUNCTION = 'easeInOut';

export const DOTS_VARIANTS = {
  hidden: { opacity: 0, y: '-100%' },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.03,
      type: 'spring',
      stiffness: 300,
      damping: 17,
    },
  }),
};

export const LIST_ITEMS_VARIANTS = {
  hidden: { opacity: 0, y: -20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: BASE_TRANSITION_DURATION * 1.2,
      delay: i * (BASE_TRANSITION_DURATION / 2),
      ease: BASE_TIMING_FUNCTION,
      type: 'spring',
      bounce: 0.4,
    },
  }),
};

export const THEME_LIGHT_TYPE = 'light';
export const THEME_DARK_TYPE = 'dark';

export const ERROR_MESSAGE = "I'm sorry, I can't fulfill this request.";
