import { useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useInView } from 'framer-motion';
import {
  FOOTER_NAV_LIST,
  HEADER_NAV_LIST,
  HOME_LINK,
  LIST_ITEMS_VARIANTS,
  MOBILE_MENU_ID,
  SOCIAL_LINKS,
  TAP_BTN_SCALE,
  THEME_DARK_TYPE,
  THEME_LIGHT_TYPE,
} from '../../../assets/utils/constants';
import './MobileMenu.css';
import LineDivider from '../../LineDivider/LineDivider';
import { CloseIcon, LogoIcon, ThemeIcon } from '../../../assets/icons/icons';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { ThemeContext } from '../../../assets/contexts/themeContext';

const btnTapAnimation = {
  scale: TAP_BTN_SCALE,
};

export default function MobileMenu({
  activeLink,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
}) {
  const { height } = useWindowSize();
  const overlay = useRef();
  const itemsRef = useRef(null);
  const itemsControls = useAnimation();
  const itemsInView = useInView(itemsRef);
  const { theme, setTheme } = useContext(ThemeContext);

  useEffect(() => {
    itemsInView
      ? itemsControls.start('visible')
      : itemsControls.start('hidden');
  }, [itemsInView, itemsControls]);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        closeMobileMenu();
      }
    }

    document.addEventListener('mousedown', handleOverlayClose);
    return () => {
      document.removeEventListener('mousedown', handleOverlayClose);
    };
  });

  function closeMobileMenu() {
    setIsMobileMenuOpen(false);
  }

  return (
    <div
      className={`mobile-menu ${isMobileMenuOpen ? 'mobile-menu_open' : ''}`}
      id={MOBILE_MENU_ID}
      ref={overlay}
      style={{
        '--social-link-border-color':
          theme === THEME_DARK_TYPE
            ? 'var(--neutral-active)'
            : 'var(--footer-neutral-primary)',
        '--logo-bg-color':
          theme === THEME_DARK_TYPE
            ? 'var(--main-primary-active)'
            : 'var(--neutral-contrast-active)',
        '--logo-border-color':
          theme === THEME_DARK_TYPE
            ? 'var(--main-primary-active)'
            : 'var(--neutral-active)',
        '--logo-fill-color':
          theme === THEME_DARK_TYPE
            ? 'var(--neutral-active)'
            : 'var(--neutral-active)',
      }}
    >
      <div
        className={`mobile-menu__container ${
          isMobileMenuOpen ? 'mobile-menu__container_open' : ''
        }`}
        ref={itemsRef}
        style={{ height: `${height}px` }}
      >
        <div className="mobile-menu__wrapper">
          <div className="mobile-menu__heading">
            <Link to={HOME_LINK.path} className="mobile-menu__icon-box">
              <LogoIcon
                mainClassName="mobile-menu__icon"
                backgroundClassName="mobile-menu__logo-icon-background"
                borderClassName="mobile-menu__logo-icon-border"
                fillClassName="mobile-menu__logo-icon-fill"
              />
            </Link>
            <motion.button
              className="mobile-menu__icon-box mobile-menu__icon-box_type_theme"
              type="button"
              whileTap={btnTapAnimation}
              onClick={() => {
                setTheme(
                  theme === THEME_DARK_TYPE ? THEME_LIGHT_TYPE : THEME_DARK_TYPE
                );
              }}
            >
              <ThemeIcon
                mainClassName="mobile-menu__theme-icon"
                fillClassName="mobile-menu__theme-icon-fill"
              />
            </motion.button>

            <motion.button
              className="mobile-menu__icon-box mobile-menu__icon-box_type_close"
              type="button"
              whileTap={btnTapAnimation}
              onClick={closeMobileMenu}
            >
              <CloseIcon
                mainClassName="mobile-menu__icon"
                backgroundClassName="mobile-menu__close-icon-background"
                fillClassName="mobile-menu__close-icon-fill"
              />
            </motion.button>
          </div>

          <nav className="mobile-menu__nav">
            <ul className="mobile-menu__links-list">
              {HEADER_NAV_LIST.map((item, i) => (
                <motion.li
                  className="mobile-menu__item"
                  key={item.label}
                  initial="hidden"
                  animate={itemsControls}
                  variants={LIST_ITEMS_VARIANTS}
                  custom={i}
                  layout
                >
                  <Link
                    className={`mobile-menu__nav-link ${
                      activeLink === item.path
                        ? 'mobile-menu__nav-link_active'
                        : ''
                    }`}
                    to={`#${item.path}`}
                    onClick={closeMobileMenu}
                  >
                    <motion.div
                      className="mobile-menu__link mobile-menu__link_type_nav"
                      whileTap={btnTapAnimation}
                    >
                      {item.label}
                    </motion.div>
                  </Link>
                </motion.li>
              ))}
            </ul>

            <div className="mobile-menu__divider-wrapper">
              <LineDivider isSmallRhomb />
            </div>

            <ul className="mobile-menu__links-list">
              {FOOTER_NAV_LIST.map((item, i) => (
                <motion.li
                  className="mobile-menu__item"
                  key={item.label}
                  whileTap={btnTapAnimation}
                  initial="hidden"
                  animate={itemsControls}
                  variants={LIST_ITEMS_VARIANTS}
                  custom={i + HEADER_NAV_LIST.length}
                >
                  <Link
                    className="mobile-menu__link"
                    to={item.path}
                    onClick={closeMobileMenu}
                  >
                    {item.label}
                  </Link>
                </motion.li>
              ))}
            </ul>

            <div className="mobile-menu__divider-wrapper">
              <LineDivider isSmallRhomb />
            </div>

            <ul className="mobile-menu__social-list">
              {SOCIAL_LINKS.map(
                (item, i) =>
                  item.link && (
                    <motion.li
                      className="mobile-menu__item"
                      key={item.label}
                      initial="hidden"
                      animate={itemsControls}
                      variants={LIST_ITEMS_VARIANTS}
                      custom={
                        i + HEADER_NAV_LIST.length + FOOTER_NAV_LIST.length
                      }
                    >
                      <motion.a
                        className="mobile-menu__social-link"
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                        whileTap={btnTapAnimation}
                      >
                        {item.icon({
                          mainClassName: 'mobile-menu__link-icon',
                          backgroundClassName:
                            'mobile-menu__link-icon-background',
                          fillClassName: 'mobile-menu__link-icon-fill',
                        })}
                      </motion.a>
                    </motion.li>
                  )
              )}
            </ul>
          </nav>

          <p className="mobile-menu__copyright">
            Ⓒ All Right Reserved – Muchi 2024
          </p>
        </div>

        <div className="mobile-menu__dots-wrapper" onClick={closeMobileMenu}>
          <ul className="mobile-menu__dots">
            {Array.from({ length: 36 }).map((_, i) => (
              <li className="mobile-menu__dot" key={i} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
