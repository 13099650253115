
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './GetStarted.css';
import { AI_FORM_ID, SIGNUP_URL, TAP_BTN_SCALE, WAITLIST_POPUP_NAME } from '../../../assets/utils/constants';
import { useContext } from 'react';
import { PopupContext } from '../../../assets/contexts/popupContext';

export default function GetStarted({ isStoryGenerated, setAiScrolled }) {
  const { openPopup } = useContext(PopupContext)
  const navigate = useNavigate();

  function handleClick() {
    if (isStoryGenerated) {
      // navigate(SIGNUP_URL);
      openPopup(WAITLIST_POPUP_NAME)
    } else {
      const element = document.getElementById(AI_FORM_ID);
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' });
        setTimeout(() => setAiScrolled(true), 800);
      }
    }
  }

  return (
    <section className="get-started">
      <div className="get-started__block">
        <p className="get-started__text">
          Ready to embark on a journey crafted just for you?
        </p>
      </div>
      <motion.button
        className="get-started__button"
        type="button"
        onClick={handleClick}
        whileHover={{ opacity: 0.9 }}
        whileTap={{ scale: TAP_BTN_SCALE }}
      >
        Get Started with Your Story
      </motion.button>
    </section>
  );
}
