import AiForm from '../../AiForm/AiForm';
import './MainAi.css';

export default function MainAi({ ...props }) {
  return (
    <section className="main-ai">
      <div className="main-ai__content">
        <AiForm {...props} />
      </div>
    </section>
  );
}
