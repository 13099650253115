import { useContext, useEffect, useRef, useState } from 'react';
import { motion, useInView, useSpring, useAnimation } from 'framer-motion';
import './Heading.css';
import { DotIcon, EyeIcon } from '../../assets/icons/icons';
import useWindowSize from '../../assets/hooks/useWindowSize';
import {
  TAP_BTN_SCALE,
  BASE_TRANSITION_DURATION,
  DOTS_VARIANTS,
  SIGNUP_URL,
  WAITLIST_POPUP_NAME,
} from '../../assets/utils/constants';
import { PopupContext } from '../../assets/contexts/popupContext';

export default function Heading({
  title,
  text,
  id,
  dotElement = DotIcon,
  dotSize = 6,
  animBlockColor = 'var(--heading-neutral-primary)',
  dotColor = 'var(--heading-color-accent-primary)',
  elementsColor = 'var(--main-primary-active)',
}) {
  const { openPopup } = useContext(PopupContext)
  const { width } = useWindowSize();
  const listLength =
    width > 1100 ? 40 : width > 740 ? 30 : width > 500 ? 40 : 28;
  const dotsRef = useRef(null);
  const dotsInView = useInView(dotsRef, { amount: 0.5 });

  const circleRef = useRef(null);
  const circleInView = useInView(circleRef);
  const x = useSpring(0, {
    stiffness: 100,
    damping: 20,
    mass: 1,
    restDelta: 0.1,
  });
  const y = useSpring(0, {
    stiffness: 100,
    damping: 20,
    mass: 1,
    restDelta: 0.1,
  });
  const [opacity, setOpacity] = useState(0);
  const [circleAnimPlayed, setCircleAnimPlayed] = useState(false);

  // Initial animation plays only once
  useEffect(() => {
    if (!circleAnimPlayed && circleInView) {
      const animation = async () => {
        setOpacity(1);
        await x.set(0);
        await y.set(0);
        setCircleAnimPlayed(true);
      };
      animation();
    }
  }, [circleInView, circleAnimPlayed, x, y]);

  // Cursor tracking across the entire document
  useEffect(() => {
    if (circleAnimPlayed) {
      const container = circleRef.current;
      const handleMouseMove = (e) => {
        const rect = container.getBoundingClientRect();

        // Cursor position relative to the container's center
        const containerCenterX = rect.left + rect.width / 2;
        const containerCenterY = rect.top + 100 + rect.height / 2;
        const offsetX = e.clientX - containerCenterX;
        const offsetY = e.clientY - containerCenterY;

        // Maximum movement constraints (50% overflow)
        const maxOffsetX = rect.width * 0.5; // 50% overflow
        const maxOffsetY = rect.height * 0.45;
        const limitedX = Math.max(-maxOffsetX, Math.min(offsetX, maxOffsetX));
        const limitedY = Math.max(-maxOffsetY, Math.min(offsetY, maxOffsetY));

        x.stop();
        y.stop();
        x.set(limitedX);
        y.set(limitedY);
      };

      // Add event listener to the entire document
      document.addEventListener('mousemove', handleMouseMove);
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [circleAnimPlayed, x, y]);

  return (
    <section
      className="heading"
      id={id}
      data-section
      style={{
        '--anim-block-color': animBlockColor,
        '--dot-color': dotColor,
        '--elements-color': elementsColor,
        '--dot-size': `${dotSize}px`,
      }}
    >
      <div className="heading__title-block">
        <div className="heading__animation-box" ref={circleRef}>
          <motion.div
            className="heading__anim-circle"
            style={{ x, y, opacity }}
            initial={{ y: -400, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              duration: BASE_TRANSITION_DURATION * 1.5,
              ease: [0.22, 1, 0.36, 1],
            }}
          />
          <EyeIcon
            mainClassName="heading__anim-box-icon"
            fillClassName="heading__anim-box-icon-fill"
          />
        </div>

        <div className="heading__text-box">
          <h1
            className="heading__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p
            className="heading__text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </div>

      <div className="heading__btn-block">
        <motion.button
          className="heading__btn"
          // href={SIGNUP_URL}
          onClick={() => { openPopup(WAITLIST_POPUP_NAME) }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: TAP_BTN_SCALE }}
        >
          JOIN NOW
        </motion.button>

        <ul className="heading__anim-list" ref={dotsRef}>
          {Array.from({ length: listLength }).map((_, i) => (
            <motion.li
              className="heading__anim-item"
              key={i}
              variants={DOTS_VARIANTS}
              initial="hidden"
              animate={dotsInView ? 'visible' : 'hidden'}
              custom={i}
              layout
            >
              {dotElement({
                mainClassName: 'heading__anim-item-icon',
                fillClassName: 'heading__anim-item-icon-fill',
              })}
            </motion.li>
          ))}
        </ul>
      </div>
    </section>
  );
}
