import './BenefitCard.css';

export default function BenefitCard({ title, text, img }) {
  return (
    <div className="benefit-card">
      <div className="benefit-card__title-box">
        <h3
          className="benefit-card__title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <img className="benefit-card__img" src={img} alt="" />
      </div>
      {text ?
        <p
          className="benefit-card__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        : null}

    </div>
  );
}
