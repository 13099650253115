export const HELP_CENTER_TITLE = `Help
Center`;

export const HELP_CENTER_TEXT = `
<h2>Frequently Asked Questions</h2>
      <ul>
        <li><strong>How do I create an account?</strong>
          <p>Click "Sign Up" on the homepage and follow the instructions to register as a reader.</p>
        </li>
        <li><strong>How do I purchase Muchi Gems?</strong>
          <p>Go to your account dashboard, select "Buy Gems," and follow the steps to complete your purchase.</p>
        </li>
        <li><strong>How do I unlock premium stories?</strong>
          <p>Use your Muchi Gems to unlock exclusive content. Look for the "Unlock with Gems" button on story pages.</p>
        </li>
      </ul>
`;
