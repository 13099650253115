import { useEffect, useRef, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BurgerIcon, LogoIcon, ThemeIcon } from '../../assets/icons/icons';
import {
  ABOUT_ID,
  HEADER_NAV_LIST,
  HOME_LINK,
  LOGIN_URL,
  TAP_BTN_SCALE,
  THEME_DARK_TYPE,
  THEME_LIGHT_TYPE,
  WAITLIST_POPUP_NAME,
} from '../../assets/utils/constants';
import './Header.css';
import MobileMenu from './MobileMenu/MobileMenu';
import useScrollSpy from '../../assets/hooks/useScrollSpy';
import useScrollDirection from '../../assets/hooks/useScrollDirection';
import { ThemeContext } from '../../assets/contexts/themeContext';
import { PopupContext } from '../../assets/contexts/popupContext';

const btnHoverAnimation = {
  opacity: 0.7,
};

const btnTapAnimation = {
  scale: TAP_BTN_SCALE,
};

export default function Header({ isInverted = false }) {
  const { openPopup } = useContext(PopupContext)
  const sections = useRef([]);
  const { pathname, hash, key } = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(hash ? hash : ABOUT_ID);
  const [ids, setIds] = useState([]);
  const activeId = useScrollSpy({ ids, offset: 100 });
  const scrollDirection = useScrollDirection();
  const { theme, setTheme } = useContext(ThemeContext);

  useEffect(() => {
    const data = [];
    sections.current = document
      .querySelectorAll('[data-section]')
      .forEach((section) => data.push(section.id));
    setIds(data);
  }, []);

  useEffect(() => {
    if (hash === '') window.scrollTo(0, 0);
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        setActiveLink(id);
        if (element) {
          element.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  useEffect(() => {
    if (activeId) setActiveLink(activeId);
  }, [activeId]);

  return (
    <header
      className={`header ${scrollDirection === 'down' ? 'header_hide' : 'header_show'
        }`}
      style={{
        '--btn-border-color': 'var(--neutral-active)',
        '--btn-bg-color': isInverted
          ? 'var(--neutral-active)'
          : 'var(--website-background)',
        '--btn-text-color': isInverted
          ? 'var(--website-background)'
          : 'var(--neutral-active)',
        '--active-link-bg-color': isInverted
          ? 'var(--website-background)'
          : 'var(--neutral-active)',
        '--active-link-text-color': isInverted
          ? 'var(--neutral-active)'
          : 'var(--website-background)',
        '--logo-bg-color':
          theme === THEME_DARK_TYPE
            ? 'var(--main-primary-active)'
            : 'var(--btn-bg-color)',
        '--logo-border-color':
          theme === THEME_DARK_TYPE
            ? 'var(--main-primary-active)'
            : 'var(--btn-border-color)',
        '--logo-fill-color':
          theme === THEME_DARK_TYPE
            ? 'var(--neutral-active)'
            : 'var(--btn-text-color)',
      }}
    >
      <div className="header__container">
        <div className="header__content-block">
          <Link className="header__icon-link" to={HOME_LINK.path}>
            <motion.div
              className="header__icon-box"
              whileHover={btnHoverAnimation}
              whileTap={btnTapAnimation}
            >
              <LogoIcon
                mainClassName="header__icon"
                backgroundClassName="header__logo-icon-background"
                borderClassName="header__logo-icon-border"
                fillClassName="header__logo-icon-fill"
              />
            </motion.div>
          </Link>

          <motion.button
            className="header__icon-box header__icon-box_type_burger"
            type="button"
            whileTap={btnTapAnimation}
            onClick={() => setIsMobileMenuOpen(true)}
          >
            <BurgerIcon
              mainClassName="header__icon"
              backgroundClassName="header__icon-background"
              borderClassName="header__icon-border"
              fillClassName="header__icon-fill"
            />
          </motion.button>

          <nav className="header__nav">
            <ul className="header__nav-list">
              {HEADER_NAV_LIST.map((item) => (
                <li className="header__nav-item" key={item.label}>
                  <Link
                    className={`header__nav-link ${activeLink === item.path ? 'header__nav-link_active' : ''
                      }`}
                    to={`#${item.path}`}
                  >
                    <motion.div
                      className="header__btn"
                      whileHover={btnHoverAnimation}
                      whileTap={btnTapAnimation}
                    >
                      {item.label}
                    </motion.div>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="header__content-block">
          <motion.button
            className="header__btn header__btn_mobile-hidden"
            type="button"
            whileHover={btnHoverAnimation}
            whileTap={btnTapAnimation}
            onClick={() => {
              setTheme(theme === THEME_DARK_TYPE ? THEME_LIGHT_TYPE : THEME_DARK_TYPE)
            }}
          >
            {/* Sign Up */}
            <ThemeIcon
              mainClassName="header__theme-icon"
              fillClassName="header__icon-fill"
            />
          </motion.button>
          <motion.button
            className="header__btn header__btn_inverted"
            // href={LOGIN_URL}
            onClick={() => { openPopup(WAITLIST_POPUP_NAME)}}
            whileHover={btnHoverAnimation}
            whileTap={btnTapAnimation}
          >
            Log In
          </motion.button>
        </div>
      </div>

      <MobileMenu
        activeLink={activeLink}
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
    </header>
  );
}
