import { useEffect, useRef, useState } from 'react';
import { motion, useAnimation, useCycle, useInView } from 'framer-motion';
import './HowItWorks.css';
import card1 from '../../../assets/images/main/how-it-works/card1.png';
import card2 from '../../../assets/images/main/how-it-works/card2.png';
import card3 from '../../../assets/images/main/how-it-works/card3.png';
import AnimationWorksCard from './AnimationWorksCard/AnimationWorksCard';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { LIST_ITEMS_VARIANTS } from '../../../assets/utils/constants';

const cards = [
  {
    title: 'Tell Us Your Desire',
    // text: 'Tell us about your fantasies.',
    img: card1,
    color: 'var(--how-it-works-color-primary)',
    highlightColor: 'var(--how-it-works-highlight-color-primary)',
  },
  {
    title: 'AI Crafts the Story',
    // text: 'Our advanced AI technology transforms your input into a&nbsp;captivating narrative.',
    img: card3,
    color: 'var(--how-it-works-color-tertiary)',
    highlightColor: 'var(--how-it-works-highlight-color-tertiary)',
  },
  {
    title: 'Dive Into Your Fantacy',
    // text: "Immerse yourself in a&nbsp;story that's truly yours, anytime, anywhere.",
    img: card2,
    color: 'var(--how-it-works-color-secondary)',
    highlightColor: 'var(--how-it-works-highlight-color-secondary)',
  },
];

export default function HowItWorks() {
  const { width } = useWindowSize();
  const [activeBtn, toggleActiveBtn] = useCycle(
    ...Array.from({ length: cards.length }, (_, i) => i)
  );

  const itemsRef = useRef(null);
  const itemsControls = useAnimation();
  const itemsInView = useInView(itemsRef, { amount: 0.5 });

  useEffect(() => {
    if (itemsInView) {
      itemsControls.start('visible');
    } else {
      itemsControls.start('hidden');
    }
  }, [itemsInView, itemsControls]);

  useEffect(() => {
    if (!itemsInView) return; // Do not set up the interval if not in view

    const interval = setInterval(() => {
      toggleActiveBtn();
    }, 8000); // 5000ms = 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount or when itemsInView changes
  }, [itemsInView, toggleActiveBtn]);

  function handleBtnClick(i) {
    toggleActiveBtn(i);
    // Optionally, reset the timer by clearing and setting up the interval again
    // This requires more complex state management, such as using useRef to store the interval ID
  }

  return (
    <section className="how-it-works">
      <div className="how-it-works__title-box">
        <h2 className="how-it-works__title">How It Works</h2>
        <div className="how-it-works__line" />
      </div>

      {width > 740 ? (
        <ul className="how-it-works__content-box">
          {cards.map((card, i) => (
            <AnimationWorksCard
              card={card}
              activeBtn={activeBtn}
              i={i}
              handleBtnClick={handleBtnClick}
              key={card.title}
            />
          ))}
        </ul>
      ) : (
        <ul className="how-it-works__content-box" ref={itemsRef}>
          {cards.map((card, i) => (
            <li
              className="how-it-works__card"
              key={card.title}
            >
              <img className="how-it-works__card-img" src={card.img} alt="" />
              <div className="how-it-works__text-box">
                <p
                  className="how-it-works__card-title"
                  dangerouslySetInnerHTML={{ __html: card.title }}
                />
                {/* <p
                  className="how-it-works__card-text"
                  dangerouslySetInnerHTML={{ __html: card.text }}
                /> */}
              </div>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
