import { AnimatePresence, motion } from 'framer-motion';
import './AnimationWorksCard.css';

export default function AnimationWorksCard({
  card,
  activeBtn,
  i,
  handleBtnClick,
}) {
  const {
    color = 'var(--how-it-works-color-primary)',
    highlightColor = 'var(--how-it-works-highlight-color-primary)',
  } = card;

  return (
    <motion.li
      className={`works-card ${activeBtn === i ? 'works-card_active' : ''}`}
      key={card.title}
      initial={{ width: '114px' }}
      animate={{ width: activeBtn === i ? '100%' : '114px' }}
      style={{
        '--btn-background-color': color,
        '--btn-highlight-color': highlightColor,
      }}
      layout
    >
      <div className="works-card__btn-box">
        <AnimatePresence mode="popLayout" initial={false}>
          {activeBtn === i ? (
            <motion.img
              className="works-card__img"
              src={card.img}
              alt=""
              key="img"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          ) : (
            <motion.button
              className="works-card__btn"
              type="button"
              onClick={() => handleBtnClick(i)}
              key="btn"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              whileHover={{
                backgroundColor: 'var(--btn-highlight-color)',
              }}
              layout
            >
              {i + 1}
            </motion.button>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {activeBtn === i && (
          <motion.div
            className="works-card__text-block"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="works-card__text-box">
              <p
                className="works-card__card-title"
                dangerouslySetInnerHTML={{ __html: card.title }}
              />
              {/* <p
                className="works-card__card-text"
                dangerouslySetInnerHTML={{ __html: card.text }}
              /> */}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.li>
  );
}
