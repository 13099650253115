import './ReviewCard.css';

export default function ReviewCard({ text, name, img }) {
  return (
    <div className="review-card">
      <div className="review-card__content-box">
        <img className="review-card__img" src={img} alt={name} />
        <p
          className="review-card__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
      <p
        className="review-card__name"
        dangerouslySetInnerHTML={{ __html: name }}
      />
    </div>
  );
}
